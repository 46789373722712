@import '../../../../styles/functions';
@import '../../../../styles/mixins';

.dashboard-table-component {
  $self: &;
  @include commonAndSubrowsTableStyles();

  th {
    font-size: 1rem;
  }

  &__center-part {
    display: flex;
    flex-direction: column;
    border-top-right-radius: unset;

    > span {
      padding-bottom: 16px;
      font-size: 1.6rem;
      font-weight: bold;
    }

    &__table {
      box-shadow: unset;

      .basic-column-header:has(.dashboard-content-columns__index-cell),
      td:has(.dashboard-content-columns__index-cell) {
        width: 9px;
        min-width: 9px;
      }

      thead tr th,
      .table-cell {
        padding: 0 0 0 0.8rem;
      }
    }
  }

  &__right-part {
    display: flex;
    flex-direction: column;
    box-shadow: unset;

    > span {
      padding-bottom: 16px;
      font-size: 1.6rem;
      font-weight: bold;
    }

    &--show-right-part-left-border table tr {
      th:first-child {
        border-image: linear-gradient(to bottom, color(cool-gray, 10) 0%, color(cool-gray, 20) 100%)
          1;
        border-width: 1px;
        border-style: solid;
      }

      td:first-child {
        border-left: 1px solid color(gray, 20);
      }
    }

    &__table {
      display: block;
      right: 0;
      border-spacing: 0;
      border-top-right-radius: 8px;
      font-size: 1.2rem;

      thead tr th {
        padding: 0 0 0 0.8rem;

        &:last-child {
          border-top-right-radius: 0px;
        }
      }

      .table-cell:not(:last-child) {
        padding: 0 0 0 0.8rem;
      }
    }
  }

  &--is-standings {
    th,
    .table-head--table-part_center tr > th {
      text-transform: uppercase;
    }

    #{$self}__center-part,
    #{$self}__right-part {
      &__table thead tr th,
      .table-cell {
        padding: 0 1.2rem;
        text-align: center;

        &:has(.standings-content-columns__team-cell),
        &:has(.standings-content-columns__logo-cell) {
          padding-left: 0;
        }
      }
    }

    #{$self}__center-part table tr {
      th:last-child,
      .table-cell:last-child {
        padding-right: 2.4rem;
      }
    }

    #{$self}__right-part table tr {
      th:first-child,
      .table-cell:first-child {
        padding-left: 2.4rem;
      }
    }

    table tr {
      td,
      th {
        width: 32px;

        &:has(.standings-content-columns__team-cell) {
          padding-left: 0;
        }

        &:has(.image-column) {
          padding-right: 0;
        }
      }
    }
  }
}
