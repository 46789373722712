@import '../../../styles/functions';

.dashboard-column-cell {
  display: flex;
  justify-content: center;

  &--is-sorted {
    font-weight: bold;
  }

  &__state {
    display: flex;
    width: 132px;
    height: 26px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    font-size: 1.2rem;
    background-image: linear-gradient(to bottom, color(cool-gray, 10), color(cool-gray, 20) 100%);
  }

  &--color_danger &__state {
    color: white;
    background-image: linear-gradient(180deg, #ed0017, #b80012 100%);
  }

  &--color_success &__state {
    color: white;
    background-image: linear-gradient(180deg, #00c259, #008f42 100%);
  }
}
