@import '../../../styles/functions';

.form-column-cell {
  display: flex;
  gap: 6px;

  &__box {
    display: flex;
    width: 24px;
    height: 26px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    color: color(white);
    font-size: 1.2rem;
    background: linear-gradient(180deg, #ed0017, #b80012);

    &--positive {
      background: linear-gradient(180deg, #00c259, #008f42);
    }
  }
}
