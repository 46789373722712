.dashboard-team-comparison {
  display: flex;
  position: relative;
  width: 100%;

  &__team {
    display: flex;
    width: 100%;
    gap: 16px;
    padding: 32px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;
    font-weight: bold;

    &__header {
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;
      gap: 8px;

      &__switch {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;

        > div {
          width: 240px;
          padding: 12px 0;
        }

        .tooltip {
          left: 50%;
          transform: translateX(-50%);
        }
      }

      svg {
        transform: translate(0px, 1px);
        transition: 0.3s;
        cursor: pointer;

        &:hover {
          transform: rotate(180deg) translate(1px, 0px);
        }
      }
    }
  }

  &__button {
    position: absolute;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
  }
}
