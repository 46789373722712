.standings-content {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-grow: 1;

  &__table {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
  }
}
