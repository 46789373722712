@import '../../../../../../styles/mixins';

.dashboard-team-tables {
  width: 100%;
  min-height: 552px;

  &__table {
    display: flex;
    flex-direction: column;
    gap: 1px;
  }
}
