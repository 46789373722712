@import '../../../../../../styles/functions';
@import '../../../../../../styles/mixins';

.defined-templates-checkboxes {
  display: flex;
  flex-direction: column;
  width: 584px;

  h3 {
    color: colorTcms(blue, 700);
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 2.4rem;
  }

  &__edit-type-buttons {
    display: flex;
    flex-direction: column;
    margin-top: 16px;

    .tcms-button {
      width: 100%;
      margin-bottom: 8px;
    }
  }

  hr {
    background-color: colorTcms(slate, 300);
  }

  &__save-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    font-size: 1.4rem;
    line-height: 2.2rem;
    margin-top: 14px;
    gap: 0.4rem;
    opacity: 0.5;
    @include disableUserSelect();

    &--is-dirty {
      color: colorTcms(blue, 700);
      opacity: 1;
      cursor: pointer;
      translate: all 0.3s ease-in-out;

      &:hover {
        color: colorTcms(blue, 800);
        text-decoration: underline;
      }
    }
  }

  @include media-query(xxl) {
    width: 484px;
  }

  @include media-query(xl) {
    width: 412px;
  }

  @include media-query(l) {
    width: 100%;
  }
}
