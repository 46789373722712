@import '../../../../styles/mixins';

.standings-content-columns {
  @include cellBase();
  height: 52px;
  border-bottom: unset;
  font-size: 1.4rem;

  &__index-cell,
  &__metric-cell {
    display: block;
    width: 32px;
    text-align: center;

    &--id_competitiongfga,
    &--id_gfga {
      width: 64px;
    }
  }

  &__logo-cell {
    width: 32px;
  }

  &__team-cell {
    display: block;
    width: 216px;
    text-align: start;
  }

  @include media-query(xxl) {
    &__minigame-cell {
      margin-left: 8px;
    }
  }

  @include media-query(xl) {
    &__gfga-cell,
    &__xgf-percent-cell,
    &__gsax-cell,
    &__game-cell,
    &__season-cell {
      width: 32px;
    }
  }
}
