@import '../../styles/functions';
@import '../../styles/mixins';

.dashboard-game-list {
  display: flex;
  flex-flow: row nowrap;
  gap: 0.5rem;

  @include media-query(xxl) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    min-width: 14.8rem;
  }
}
