@import '../../../../styles/mixins';

.dashboard-content-columns {
  @include cellBase();
  height: 52px;
  border-bottom: unset;
  font-size: 1.4rem;

  &__index-cell {
    display: block;
    text-wrap: nowrap;
    font-weight: bold;
    text-transform: uppercase;

    &--header {
      width: 9px;
      padding-left: 0.8rem;
    }
  }

  &__special-teams-cell--header {
    text-transform: uppercase;
  }

  &__formation-cell {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }

  &__gfga-cell,
  &__xgf-percent-cell,
  &__gsax-cell,
  &__game-cell,
  &__season-cell {
    display: block;
    width: 80px;

    &--color_success {
      color: color(green, 60);
    }

    &--color_danger {
      color: color(red, 60);
    }
  }

  &__special-teams-cell {
    display: block;
    width: 100%;
    text-align: start;

    &--header {
      padding-left: 0.8rem;
    }
  }

  &__minigame-cell {
    display: block;
    width: 132px;
    margin-left: 53px;
  }

  @include media-query(xxl) {
    &__minigame-cell {
      margin-left: 8px;
    }
  }

  @include media-query(xl) {
    &__gfga-cell,
    &__xgf-percent-cell,
    &__gsax-cell,
    &__game-cell,
    &__season-cell {
      width: 36px;
    }
  }
}
