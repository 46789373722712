@import '../../styles/functions';
@import '../../styles/mixins';

.minigraph {
  display: flex;
  position: relative;
  width: 100px;
  flex-direction: column;
  justify-content: center;
  gap: 0.8rem;
  $self: &;

  &--is-loaded {
    cursor: pointer;
  }

  &__label {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 2.2rem;
    gap: 0.2rem;
  }

  &__order {
    display: flex;
    width: 100%;
    justify-content: center;
    color: #878d96;
    font-size: 2.4rem;
    font-weight: bold;
    text-align: center;
    line-height: 3.2rem;
  }

  &__battery {
    position: relative;
    width: 100%;
    height: 40px;
    border-radius: 8px;
    background: color(cool-gray, 10);

    &__tooltip {
      display: flex;
      justify-content: center;
      width: 19rem;

      &--big {
        width: 25rem;
      }
    }

    &__battery-value {
      height: 100%;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      background: linear-gradient(90deg, #a2a9b0, #697077);
      transition: width 0.3s;
    }
  }

  &--is-battery-full &__battery__battery-value {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &--color_green {
    #{$self}__battery {
      background: #defbe6;

      &__battery-value {
        background: linear-gradient(90deg, #00c259, #008f42);
      }
    }

    #{$self}__order {
      color: color(green);
    }
  }

  &--color_red {
    #{$self}__battery {
      background: #fff1f1;

      &__battery-value {
        background: linear-gradient(90deg, #ed0017, #b80012);
      }
    }

    #{$self}__order {
      color: color(red);
    }
  }

  @include media-query(xxl) {
    width: 80px;
  }

  @include media-query(xl) {
    width: 100px;
  }
}
