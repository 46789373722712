@import '../../../../../../styles/mixins';

.dashboard-team-side {
  display: flex;
  width: calc(50% - 16px);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;

  &__error {
    height: 100%;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;

    .button {
      width: 232px;
    }
  }

  @include media-query(xl) {
    &__list {
      display: flex;
      width: 100%;
    }
  }
}
