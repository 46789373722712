.dashboard-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &__team-rest {
    display: flex;
    gap: 32px;
  }
}
