@import '../../styles/functions';

.logo-card-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;

  &--center-content {
    justify-content: center;
  }
}
