@import '../../../../../../styles/functions';
@import '../../../../../../styles/mixins';

.default-templates {
  display: flex;
  flex-direction: column;
  width: 488px;

  h3 {
    color: colorTcms(blue, 700);
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 2.4rem;
  }

  &--size_medium {
    width: 392px;
  }

  &--size_small {
    width: 296px;
  }
}
