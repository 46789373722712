@import '../../../../../../styles/mixins';

.dashboard-team-game-list-with-summary {
  &__skeleton-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5rem;
  }

  &__overview {
    width: 100%;
  }
}
