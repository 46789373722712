@import '../../../styles/functions';

.tcms-modal {
  $self: &;

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    &__title {
      margin-bottom: 16px;
      color: colorTcms(blue, 700);
      font-size: 1.6rem;
      font-weight: bold;
    }

    #{$self}--is-empty &__title {
      margin-bottom: unset;
    }
  }

  &__content {
    min-width: 440px;
  }
}
