@import '../../../styles/functions';

.table-row {
  background-color: color(white);

  &--disable-bottom-border .table-cell {
    border-bottom: none;
  }

  &--hide {
    display: none;
  }
}
