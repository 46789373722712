@import '../../styles/functions';
@import '../../styles/mixins';

.dashboard-game-button {
  display: flex;
  width: fit-content;
  min-width: 14.68rem;
  padding: 0.8rem;
  flex-direction: column;
  align-items: center;
  border: 2px solid color(red, 10);
  border-radius: 8px;
  background-color: color(red, 10);
  box-sizing: border-box;
  cursor: pointer;
  $self: &;

  &--is-active {
    border-color: color(red, 60);
  }

  &--positive {
    background-color: color(green, 10);
    border-color: color(green, 10);

    &#{$self}--is-active {
      border-color: color(green, 60);
    }
  }

  &__date {
    font-size: 1rem;
    line-height: 1.6rem;
    font-weight: normal;
    margin: 0;
  }

  &__teams {
    font-size: 1.2rem;
    line-height: 1.8rem;
    font-weight: bold;
    margin: 0;
  }

  &__score {
    font-size: 2rem;
    line-height: 2.8rem;
    font-weight: bold;
    color: color(red, 60);
    margin: 0.4rem 0;

    &--positive {
      color: color(green, 60);
    }
  }

  &__bar {
    width: 100%;
    height: 10px;
    border-radius: 2px;
    background-color: color(white);
    padding: 0.2rem;
    margin-bottom: 0.4rem;

    &__inner {
      height: 100%;
      background-color: color(cool-gray, 60);
      border-radius: 2px;

      &--color_success {
        background-color: color(green, 60);
      }

      &--color_danger {
        background-color: color(red, 60);
      }
    }

    &__label {
      font-size: 1.2rem;
      line-height: 1.8rem;
      font-weight: bold;
      margin: 0;

      span {
        font-weight: normal;
      }
    }
  }

  @include media-query(xxl) {
    min-width: 12.28rem;
  }

  @include media-query(xl) {
    min-width: 14.68rem;
  }
}
