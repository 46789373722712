@import '../../../../styles/functions';
@import '../../../../styles/mixins';

.help-management-page {
  flex-direction: column;
  @include tcmsPageContainer();

  &__controls {
    display: flex;
    flex-direction: column;
    padding: 24px 0;

    h2 {
      opacity: 1;
      color: colorTcms(blue, 700);
      font-size: 1.6rem;
      font-weight: bold;
      line-height: 2.4rem;
    }

    &__buttons {
      display: flex;
      gap: 9px;

      .tcms-button {
        width: 100%;
      }
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__flags {
        margin-top: 2px;
      }
    }
  }

  &__content {
    display: flex;
    flex-grow: 1;
    gap: 40px;
    padding: 24px;
    @include card();

    .content-info-box {
      width: 488px;
    }

    @include media-query(xl) {
      gap: 24px;
    }

    &--is-description {
      justify-content: space-between;
    }
  }
}
