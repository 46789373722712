@import '../../styles/functions';
@import '../../styles/mixins';

.minigraph-list {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 16px;

  @include media-query(xl) {
    display: grid;
    grid-template-columns: repeat(3, 130px);
    grid-template-rows: repeat(2, 1fr);
    justify-items: center;
  }
}
