@import '../../styles/functions';

.logo-card {
  display: flex;
  width: 80px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid colorTcms(slate, 300);
  background-color: color(white);
  transition: border 0.25s;
  cursor: pointer;

  &--shape_square &__logo {
    max-width: 32px;
    max-height: 32px;
  }

  &--team-id_all#{&}--shape_circle img {
    width: 32px;
    height: 32px;
  }

  &--shape_circle {
    box-shadow:
      0px 2px 12px rgba(color(cool-gray, 100), 0.08),
      0px 2px 2px rgba(color(cool-gray, 100), 0.04);
    border-radius: 50%;
    border: none;
    background-color: color(white);
    width: 64px;
    height: 64px;
    display: grid;
    place-items: center;
    cursor: auto;

    img {
      width: 48px;
      height: 48px;
      height: auto;
      object-fit: contain;
    }
  }

  &--is-clickable {
    border: 1px solid colorTcms(slate, 300);
    cursor: pointer;

    &:hover {
      border: 1px solid colorTcms(slate, 400);
    }
  }

  &--hover-effect_borderless {
    border-color: transparent;

    &:hover {
      border-color: colorTcms(blue, 700);
    }
  }

  &--is-active,
  &--is-active:hover {
    border: 1px solid colorTcms(blue, 700);
    outline: 3px solid colorTcms(blue, 100);
  }

  &--disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}
