@import '../../styles/functions';
@import '../../styles/mixins';

.dashboard-page {
  &__content-container {
    flex-grow: 1;
    flex-direction: column;
    @include pageContainer();
  }

  &__team-select-bar {
    display: flex;
    width: 100%;
    height: 122px;
    justify-content: center;
    align-items: center;
    background-color: color(cool-gray, 10);
  }
}
